import api from './api'


const fileReducer = (state, action) => {
  // If the files have been selected but not submitted...
  if (action.type === 'load') {
    return state.concat(action.filePayload)
  }

  // If the file has begun the process
  if (action.type === 'begin') {
    return state.map(filePayload => {
      if (filePayload.file.name === action.payload.name) {
        filePayload.percentUploaded = 0
        filePayload.uploadStarted = true
      }
      return filePayload
    })
  }

  // If the files have been submitted and their upload percent has changed...
  if (action.type === 'percentChanged') {
    const updatedPayload = state.map(filePayload => {
      if (filePayload.file.name === action.payload.name) {
        filePayload.percentUploaded = action.payload.percentUploaded
      }
      return filePayload
    })

    return updatedPayload
  }

  if (action.type === 'fileProcessed') {
    return state.filter(filePayload => {
      return filePayload.percentUploaded < 100
    })
  }

  if (action.type === 'clearFiles') {
    return []
  }

  // Otherwise just return what we've got
  return state
}


// Helper to get the details needed to upload directly to a DO Space from the
// browser.
const getUploadDetails = async (filePayload, projectId) => {
  // Get a signed URL for upload files
  const workInput = {
    name: filePayload.file.name,
    contentType: filePayload.file.type,
    projectId: projectId,
  }

  const getUploadDetails = {
    query: `
        query (
          $workInput: WorkInput!,
        ) {
          workUploadDetails(
            workInput: $workInput
          ) {
            signedUrl
            originalName
            uniqueName
            contentType
            key
            projectId
            mediaLocation
          }
        }
      `,
    variables: {
      workInput
    }
  }

  const res = await api.makeGraphQlRequest(getUploadDetails)
  if (res.errors && res.errors.length) {
    if (res.errors[0].message === 'File upload limit exceeded') {
      throw new Error('File upload limit exceeded')
    }
  }
  const details = res.data.workUploadDetails

  if (!details && !details.signedUrl) {
    console.log('There was an error getting the file store URL') // eslint-disable-line no-console
  }

  return details
}


// Helper to actually upload the files to s3 based on upload details.
const upload = (filePayload, signedUrl, dispatch) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('PUT', signedUrl)
    xhr.setRequestHeader('Content-Type', filePayload.file.type)
    xhr.setRequestHeader('x-amz-acl', 'public-read')

    xhr.upload.onprogress = (e) => {
      const done = e.position || e.loaded
      const total = e.totalSize || e.total
      const percentUploaded = (Math.floor(done/total*1000)/10)

      dispatch({
        type: 'percentChanged',
        payload: {
          name: filePayload.file.name,
          percentUploaded: Math.min(percentUploaded, 100),
        }
      })
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response)
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        })
      }
    }

    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      })
    }

    xhr.send(filePayload.file)
  })
}


// Add a work for the project to the DB
const addNew = async uploadDetails => {
  const workInput = {
    name: uploadDetails.originalName,
    contentType: uploadDetails.contentType,
    projectId: uploadDetails.projectId,
    mediaLocation: uploadDetails.mediaLocation,
  }

  const body = {
    query: `mutation($workInput: WorkInput!) {
      createWork(workInput: $workInput) {
        id
        name
        versions {
          id
          id
          name
          media_location
          created_at
        }
      }
    }`,
    variables: {
      workInput,
    }
  }

  const response = await api.makeGraphQlRequest(body)
  const work = response.data.createWork
  return work
}


const addVersion = async (workId, uploadDetails) => {
  const workVersionInput = {
    workId,
    name: uploadDetails.originalName,
    mediaLocation: uploadDetails.mediaLocation,
  }

  const body = {
    query: `mutation($workVersionInput: WorkVersionInput!) {
      createWorkVersion(workVersionInput: $workVersionInput) {
        id
        name
        media_location
        created_at
      }
    }`,
    variables: {
      workVersionInput,
    }
  }

  const response = await api.makeGraphQlRequest(body)

  return response.data.createWorkVersion
}


// Helper to do upload works and set them in the DB for a given project, in the
// correct order.
const createNew = async (filePayload, projectId, dispatch) => {
  dispatch({
    type: 'begin',
    payload: {name: filePayload.file.name}
  })

  const uploadDetails = await getUploadDetails(filePayload, projectId)
  await upload(filePayload, uploadDetails.signedUrl, dispatch)
  const results = await addNew(uploadDetails)

  dispatch({
    type: 'fileProcessed',
    payload: {name: filePayload.file.name}
  })

  return results
}


const createVersion = async (filePayload, projectId, workId, dispatch) => {
  dispatch({
    type: 'begin',
    payload: {name: filePayload.file.name}
  })
  const uploadDetails = await getUploadDetails(filePayload, projectId)
  await upload(filePayload, uploadDetails.signedUrl, dispatch)

  dispatch({
    type: 'fileProcessed',
    payload: {name: filePayload.file.name}
  })

  return await addVersion(workId, uploadDetails)
}


export default {
  createNew,
  createVersion,
  fileReducer,
}
