import React, { useState, useReducer } from "react";

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Redirect } from 'react-router-dom'
import EmailStep from './signup_steps/Email'

import "./LandingPage.scss";
import graphic from "./assets/graphic.png";
import Editor from "./assets/editor.png";
import idel1 from "./assets/idel_for_1.jpg"
import idel2 from "./assets/idel_for_2.jpg"
import idel3 from "./assets/idel_for_3.jpg"
import idel4 from "./assets/idel_for_4.jpg"

import FileUpload from './FileUpload'
import works from '../lib/works'

const LandingPage = ({ projectId, updateProjectWithNewWorks }) => {
  const [show, setShow] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [files, dispatch] = useReducer(works.fileReducer, [])
  const [isUploading, setIsUploading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorText, setErrorText] = useState()

  const handleClose = () => {
    dispatch({
      type: 'clearFiles',
    })
    setShow(false)
  }
  // const handleShow = () => setShow(true)

  const uploadNewWorks = async () => {
    setIsUploading(true)

    // Handle uploading works to Spaces and the DB
    // const promises = []
    // for (const file of files) {
    //   promises.push(works.createNew(file, parseInt(projectId), dispatch))
    // }

    setRedirect(true)
    try {
      // const results = await Promise.all(promises)
      // updateProjectWithNewWorks(results)
    }
    catch (err) {
      // Show error modal
      setErrorText(<p>{err.message}</p>)
      setShowErrorModal(true)
      // TODO: send to rollbar
    }

    setIsUploading(false)

    handleClose()
  }

  return (
    <div className="landing-page container">
      {/* <div className="why">
        <div>
          <h2>
            Why use
            <br />
            Episode Check?
          </h2>
          <div>Upload and get feedback on podcast episode drafts from one or many</div>
          <div>Easily track the latest version and comments in one place</div>
          <div>All audio formats supported so Episode Check can be your audio cloud storage</div>
          <LinkContainer to="/signup/email"><Nav.Link><div>Get Started</div></Nav.Link></LinkContainer>
        </div>
        <img src={graphic} alt="Versions Graphic" width="1200" height="20000" />
      </div> */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Upload Episode</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FileUpload
            files={files}
            dispatch={dispatch}
            isUploading={isUploading} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isUploading}
            variant="primary"
            onClick={uploadNewWorks}
          >Upload</Button>
        </Modal.Footer>
      </Modal>
      {redirect && <Redirect to="/signup/email" />}

      <div className="row">
        <div className="col-md-6">
          <div className="project_editor">
            <h4>A Podcast Editor’s <span>Best Friend</span></h4>
            <ul>
              <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Send drafts to one or more reviewers</li>
              <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Track versions and comments all in one place</li>
              <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> All audio format is supported</li>
              <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Use as your audio storage</li>
            </ul>
            <Button onClick={() => setRedirect(true)}>Try it now for free</Button>
          </div>
        </div>
        <div className="col-md-6 img_project_editor">
          <img className="img_editor" src={Editor} alt="project editor" />
        </div>
      </div>
      <div className="steps_wrap">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="steps_card">
              <div className="icon">
                <FontAwesomeIcon icon={faUpload} />
              </div>
              <h5>Upload your episode</h5>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="steps_card">
              <div className="icon">
                <FontAwesomeIcon icon={faShare} />
              </div>
              <h5>One link to share</h5>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="steps_card">
              <div className="icon">
                <FontAwesomeIcon icon={faComments} />
              </div>
              <h5>
                Collect feedback <br /> track versions
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="upload_file">
        <h4>Upload a File to start your free account</h4>
        <FontAwesomeIcon icon={faUpload} />
        <p className="drag_drop">Drag & Drop</p>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="inputGroupFile01" />
          <label class="custom-file-label" for="inputGroupFile01">
            Browse Files
          </label>
        </div>
        <p>All formats supported including AAC, mp3. m4a, wav and more</p>
      </div> */}
      <div className="account_wrap">
        <div className="bg_account_wrap"></div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="account_card">
              <h4>Free Account</h4>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Up to 1GB storage</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> 1 Project</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> No credit card required</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Always full audio resolution</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> No log in required for clients to give feedback</li>
              </ul>
              <LinkContainer to="/signup/email"><button className="free_account">Try it now for free</button></LinkContainer>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="account_card">
              <h4>Premium <span>Account</span></h4>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Up to 100GB storage</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Unlimited projects, episodes and revisions</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Billed annually</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Always full audio resolution</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> No log in required for clients to give feedback</li>
              </ul>
              <button className="premium_account">Only $2 /month</button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="steps">
        <div>
          <FontAwesomeIcon icon={faUpload} />
          <h3>Upload your episode</h3>
        </div>
        <div></div>
        <div>
          <FontAwesomeIcon icon={faShare} />
          <h3>One link to share</h3>
        </div>
        <div></div>
        <div>
          <FontAwesomeIcon icon={faComments} />
          <h3>Collect feedback &amp; track versions</h3>
        </div>
      </div> */}
      {/* <div className="features">
        <div>
          <div>
            <h3>FREE trial</h3>
            <ul>
              <li>No login necessary for clients</li>
              <li>Full resolution audio</li>
              <li>Comment notifications via email</li>
              <li>1 project</li>
              <li>1 GB of storage</li>
              <li>No credit card required</li>
            </ul>
          </div>
          <div></div>
          <div>
            <h3>Ongoing</h3>
            <ul>
              <li>Same features as free tier PLUS</li>
              <li>Unlimited projects</li>
              <li>Unlimited episodes</li>
              <li>Unlimited revisions</li>
              <li>Unlimited collaborators</li>
              <li>100 GB storage</li>
              <li>$24 AUD per year</li>
            </ul>
          </div>
        </div>
        <LinkContainer to="/signup/email">
          <Nav.Link>
            <div>Start Free Trial</div>
          </Nav.Link>
        </LinkContainer>
      </div> */}
      <div className="ideal_for">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-12">
            <div className="ideal_for_info">
              <h3>Ideal for</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Podcast production companies and clients</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Podcasts with a team of two or more</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Branded shows where approvals are necessary</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Editors/creative directors approving multiple shows</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Networks with central editing capacity</li>
                <li><FontAwesomeIcon icon={faCheck} className="checkIcon" /> Feedback on audio advertisements</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-12">
            <div className="ideal_for_img">
              <div className="row">
                <div className="left_img col-6 nopadding">
                  <img src={idel1} alt="idel1" />
                  <img src={idel2} alt="idel2" />
                </div>
                <div className="right_img col-6 nopadding">
                  <img src={idel3} alt="idel3" />
                  <img src={idel4} alt="idel4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ideal">
        <h2>Ideal for</h2>
        <ul>
          <li>Podcast production companies and clients</li>
          <li>Podcasts with a team of two or more</li>
          <li>Branded shows where approvals are necessary</li>
          <li>Editors/creative directors approving multiple shows</li>
          <li>Networks with central editing capacity</li>
          <li>Feedback on audio advertisements</li>
        </ul>
      </div> */}
    </div>
  );
};

export default LandingPage;
