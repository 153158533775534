import React, {useState, useEffect} from 'react'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'

import {useInputs} from '../hooks/useInputs'


const InlineRename = ({handleSave, children, label}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [inputElement, setInputElement] = useState()
  const {inputValues, handleInputChange} = useInputs({name:children})

  useEffect(() => {
    if (inputElement && isEditing) inputElement.select()
  }, [inputElement, isEditing])

  function handleSaveClick() {
    handleSave(inputValues.name)
    setIsEditing(false)
  }


  if (!isEditing) {
    return (
      <>
        {label}&nbsp;
        {children}
        <FontAwesomeIcon
          icon={faEdit}
          style={{fontSize: '14px', marginLeft: '.6em',  cursor: 'pointer', color: '#929292'}}
          onClick={() => setIsEditing(true)} />
      </>
    )
  }

  return (
    <Row>
      <Col
        lg="auto"
        md="auto">
        {label}
      </Col>
      <Col>
        <Form.Control
          ref={input => setInputElement(input)}
          name="name"
          type="text"
          value={inputValues.name || ''}
          placeholder="Version Description"
          onChange={ handleInputChange } />
      </Col>
      <Col>
        <Button
          onClick={handleSaveClick}
          variant="outline-primary">
            Save
        </Button>
        <Button
          onClick={() => setIsEditing(false)}
          variant="link">
            Cancel
        </Button>
      </Col>
    </Row>
  )
}


export default InlineRename
