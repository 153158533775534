import api from './api'


// Helper to create a new project based on form inputs.
const create = async name => {
  const body = {
    query: `mutation($name: String!) {
        createProject(name: $name){
          id
        }
      }`,
    variables: {
      name,
    }
  }
  const res = await api.makeGraphQlRequest(body)

  if (res.errors && res.errors.length) {
    if (res.errors[0].message === 'File upload limit exceeded') {
      throw new Error('File upload limit exceeded')
    }
  }

  const projectId = res.data.createProject.id

  return projectId
}


// Helper to delete a project.
// Can't call this delete because it's a reserved word
const deleteProject = async id => {
  const body = {
    query: `mutation($id: Int!) {
        deleteProject(id: $id) {
          id
        }
      }`,
    variables: {id}
  }
  const res = await api.makeGraphQlRequest(body)
  const projectId = res.data.deleteProject

  return projectId
}


export default {
  create,
  delete: deleteProject
}
