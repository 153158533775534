import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useInputs } from '../hooks/useInputs'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import './signup_steps/Step.scss'
import GenericErrorModal from './GenericErrorModal'

import api from '../lib/api'
import authClient from '../Auth'
import validators from '../lib/validators'


const Login = ({ history, setUser }) => {
  const [emailError, setEmailError] = useState()
  const [passwordError, setPasswordError] = useState()
  const { inputValues, handleInputChange } = useInputs()
  const [isInvalid, setIsInvalid] = useState(new Set())
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!isValid()) return

    const { email, password } = inputValues

    const requestBody = {
      email,
      password,
    }

    const res = await api.makeRestRequest('login', 'POST', requestBody)
    if (res.status === 401) {
      const error = 'Your email doesn’t match your password. Please try again.'
      setPasswordError(error)
      return setIsInvalid(new Set(['password']))
    }
    if (res.status === 200) {
      setUser(await authClient.silentAuth())
      // The line above triggers the user getting kicked out of
      // this anonymous route and sent to /projects
    }
    else {
      setShowErrorModal(true)
    }
  }


  const isValid = () => {
    const { email, password } = inputValues

    // Check the form for validity
    const problems = new Set()

    const emailMessage = validators.email_address(email)
    const godLogin = email && email.includes('::')
    if (emailMessage && !godLogin) {
      problems.add('email')
      setEmailError(emailMessage)
    }

    const passwordMessage = validators.password(password)
    if (passwordMessage) {
      problems.add('password')
      setPasswordError(passwordMessage)
    }

    if (problems.size) setIsInvalid(problems)

    // If there are no problems return true
    return problems.size === 0
  }

  const forgotPass = () => {
    return history.replace('/forgotPassword')
  }

  return (
    <Card className="step">
      <Card.Body>
        <p>Login</p>
        <Form onSubmit={handleSubmit} className="form">
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="text"
              onChange={handleInputChange}
              isInvalid={isInvalid.has('email')}
              autoFocus />

            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              onChange={handleInputChange}
              isInvalid={isInvalid.has('password')} />
            <Form.Control.Feedback type="invalid">
              {passwordError}
            </Form.Control.Feedback>
            <Form.Text className="text-muted form_text">
              <a href="#" onClick={forgotPass}>Forgot the password?</a>
            </Form.Text>
          </Form.Group>

          <Button
            type="submit"
            className="nextButton">Log In</Button>
        </Form>
      </Card.Body>

      <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal} />
    </Card>
  )
}

export default withRouter(Login)
