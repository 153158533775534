import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useInputs } from '../../hooks/useInputs'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import api from '../../lib/api'
import validators from '../../lib/validators'

import './Step.scss'


const ForgotEmail = ({ history, location }) => {
  const { inputValues, handleInputChange } = useInputs()
  const [isInvalid, setIsInvalid] = useState(new Set())
  const [emailError, setEmailError] = useState()

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isValid()) return false

    const { email } = inputValues

    const body = {
      query: `
        mutation($email: String!) {
          beginPasswordReset(email: $email)
        }
      `,
      variables: {
        email,
      }
    }

    await api.makeGraphQlRequest(body)

    history.push('/login')
  }

  // Handles validating the form before submit
  const isValid = () => {

    const { email } = inputValues

    // Check the form for validity
    const problems = new Set()

    const message = validators.email_address(email)
    if (message) {
      problems.add('email')
      setEmailError(message)
    }

    if (problems.size) setIsInvalid(problems)

    // If there are no problems return true
    return problems.size === 0
  }

  return (
    <div className="successfully_file_wrapper">
      <Card className="step">
        <Card.Body>
          <Form onSubmit={handleSubmit} className="form">
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                onChange={handleInputChange}
                isInvalid={isInvalid.has('email')}
                autoFocus />

              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>

              <Form.Text className="text-muted form_text">
                We&apos;ll send you the reset password link to your email.
              </Form.Text>
            </Form.Group>

            <Button
              type="submit"
              // onClick={() => setRedirect(true)}
              className="nextButton"
              variant="outline-primary">Send mail</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default withRouter(ForgotEmail)
