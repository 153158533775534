import React, { useState, useReducer } from 'react'
import { withRouter } from 'react-router-dom'

import works from '../lib/works'
import projects from '../lib/projects'
import { useInputs } from '../hooks/useInputs'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import GenericErrorModal from './GenericErrorModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// Project Components
import FileUpload from './FileUpload'
import UserContext from './UserContext'


const NewProject = ({ history, projectList }) => {
  const user = React.useContext(UserContext)
  const [show, setShow] = useState(false)
  const { inputValues, handleInputChange } = useInputs()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorText, setErrorText] = useState()

  // We'll use a reducer hook to keep track of file upload percents.
  const [files, dispatch] = useReducer(works.fileReducer, [])

  const handleClose = () => {
    dispatch({
      type: 'clearFiles',
    })
    setShow(false)
  }
  const handleShow = () => {
    if (user.stripe_id || projectList.length <= 0) {
      setShow(true)
    } else {
      history.replace('/purchase')
    }
    // if (user.stripe_id) {
    //   setShow(true)
    // } else {
    //   history.replace('/purchase')
    // }
  }

  const handleSubmit = async event => {
    // Don't do a form submit
    event.preventDefault()

    // Disable buttons and whatnot
    setIsSubmitting(true)

    let projectId
    try {
      projectId = await projects.create(inputValues.projectName)
    }
    catch (err) {
      setErrorText(<p>{err.message}</p>)
      return
    }

    // Handle uploading works to S3 and the DB
    const promises = []
    for (const file of files) {
      promises.push(works.createNew(file, projectId, dispatch))
    }

    await Promise.all(promises)

    // Enable buttons and whatnot
    setIsSubmitting(false)

    // Redirect to the newly created project
    history.replace(`/project/${projectId}`)
  }

  // Deny creating more than one project for non-paying customers
  if (show && user.status !== 'active' && projectList.length > 0) {
    return <GenericErrorModal show={show} setShow={setShow} title={'Paid Feature'}>
      <p><a href="/purchase">Subscribe</a> to create more projects.</p>
    </GenericErrorModal>
  }

  // Error for being over the storage space limit
  if (show && errorText) {
    return <GenericErrorModal show={show} setShow={setShow}>
      {errorText}
    </GenericErrorModal>
  }

  return (
    <>
      <Button
        variant="outline-primary"
        style={{ margin: '20px auto', display: 'block' }}
        onClick={handleShow} >
        <FontAwesomeIcon
          style={{ fontSize: '1em', cursor: 'pointer' }}
          icon={faPlus} />
        &nbsp; New Project
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>New Project</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="formProjectName">
              <Form.Label>Step 1: Name the project</Form.Label>
              <Form.Control
                name="projectName"
                type="text"
                placeholder="Project Name"
                onChange={handleInputChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Step 2: Select some episodes (optional)
              </Form.Label>
              <FileUpload
                files={files}
                dispatch={dispatch}
                isUploading={isSubmitting} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!inputValues.projectName || isSubmitting}
              className="float-right">Create Project</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default withRouter(NewProject)
