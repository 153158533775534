import api from './api'


const deleteWorkVersion = async (id) => {
  const body = {
    query: `
      mutation($id: Int!) {
        deleteWorkVersion(id: $id)
      }
    `,
    variables: {id}
  }

  const response = await api.makeGraphQlRequest(body)

  return response.data.deleteWorkVersion
}


export default {
  deleteWorkVersion,
}
