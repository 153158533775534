import React from 'react'
import Container from 'react-bootstrap/Container'

const Privacy = props => {
  return (
    <Container fluid={true} style={{maxWidth:'1200px'}}>
      Privacy policy goes here.
    </Container>
  )
}

export default Privacy
