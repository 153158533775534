import React from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

const GenericErrorModal = ({show, setShow, title, children}) => {
  const handleClose = () => {
    setShow(false)
  }

  const defaultMessage = <p>
    Sorry, something went wrong.
    <br/>
    It&apos;s our fault, please reload and try again.
  </p>

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Error'}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{textAlign: 'center'}}>
          <FontAwesomeIcon
            style={{fontSize: '2.5em', marginBottom: '15px'}}
            icon={faExclamationTriangle}
          />

          {children || defaultMessage}

        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GenericErrorModal
