import React from 'react'
import Container from 'react-bootstrap/Container'

const Terms = props => {
  return (
    <Container fluid={true} style={{maxWidth:'1200px'}}>
      Terms of service go here.
    </Container>
  )
}

export default Terms
