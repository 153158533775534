import api from './lib/api'


const authClient = {

  async silentAuth() {
    const response = await fetch('/api/me')

    try {
      return await response.json()
    }
    catch (e) {
      return null
    }
  },


  async logout() {
    await api.makeRestRequest('logout')
  },

}

export default authClient
