import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useInputs } from '../../hooks/useInputs'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import api from '../../lib/api'
import validators from '../../lib/validators'

import './Step.scss'


const Email = ({ history, location }) => {
  const { inputValues, handleInputChange } = useInputs()
  const [isInvalid, setIsInvalid] = useState(new Set())
  const [emailError, setEmailError] = useState()

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isValid()) return false

    const { email } = inputValues

    const body1 = {
      query: `query($email: String) {
        meEmail(email: $email) {
          email
        }
      }`,
      variables: {
        email,
      }
    }

    const response = await api.makeGraphQlRequest(body1)
    if (response.data.meEmail) {
      setEmailError("Account already exist with this email address")
    } else {
      const body = {
        query: `mutation($email: String!) {
        createMe(email: $email) {email}
      }`,
        variables: {
          email,
        }
      }
      await api.makeGraphQlRequest(body)

      history.push({ pathname: '/signup/createpassword', state: { email } })
    }
  }

  // Handles validating the form before submit
  const isValid = () => {

    const { email } = inputValues

    // Check the form for validity
    const problems = new Set()

    const message = validators.email_address(email)
    if (message) {
      problems.add('email')
      setEmailError(message)
    }

    if (problems.size) setIsInvalid(problems)

    // If there are no problems return true
    return problems.size === 0
  }

  return (
    <div className="successfully_file_wrapper">
      {/* <div className="successfully_file">
        <div className="successfully_icon"></div>
        <p>File "alaska.mp3" successfully loaded</p>
        <button>undo</button>
      </div> */}
      <Card className="step">
        <Card.Body>
          <p>Login to view, edit and share your files</p>
          <Form onSubmit={handleSubmit} className="form">
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                onChange={handleInputChange}
                isInvalid={isInvalid.has('email')}
                autoFocus />


              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
              <span style={{ color: "red" }}>{emailError}</span>
              <Form.Text className="text-muted form_text">
                We&apos;ll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Button
              type="submit"
              // onClick={() => setRedirect(true)}
              className="nextButton"
              variant="outline-primary">Submit</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default withRouter(Email)
