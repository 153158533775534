import React from 'react'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import queryString from 'query-string'

import validators from '../lib/validators'
import { useInputs } from '../hooks/useInputs'
import api from '../lib/api'



const ResetPassword = ({ history,location }) => {
  const params = queryString.parse(location.search)

  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [validated, setValidated] = useState(false)
  const [isInvalid, setIsInvalid] = useState(new Set())
  const { inputValues, handleInputChange } = useInputs({
    email: params.email
  })

  // The new password field can have many potential issues. This stores it.
  const [newPassword1Error, setNewPassword1Error] = useState()

  async function handlePasswordSubmit(event) {
    event.preventDefault()
    // Reset success
    setPasswordSuccess(false)

    if (!isPasswordValid()) return

    const { email, newPassword1 } = inputValues

    // Submit to the API if no validation errors
    try {
      const body = {
        query: `
          mutation($email: String!, $newPass: String!) {
            resetPassword(email: $email, newPass: $newPass)
          }
        `,
        variables: {
          email,
          newPass: newPassword1
        }
      }

      await api.makeGraphQlRequest(body)

      history.push('/login')
    }
    catch (err) {

      window.alert(err.message)

    }
  }

  // Handles validating the form before submit
  const isPasswordValid = () => {
    const { newPassword1, newPassword2 } = inputValues

    const problems = new Set()
    const message = validators.password(newPassword1)
    if (message) {
      problems.add('newPassword1')
      setNewPassword1Error(message)
    }
    if (newPassword2 !== newPassword1) problems.add('newPassword2')

    setIsInvalid(problems)
    setValidated(true)

    return problems.size === 0
  }

  return <>
    {passwordSuccess && <Alert variant={'primary'}>
      Password successfully changed
    </Alert>}

    <Form onSubmit={handlePasswordSubmit}>

      <Form.Group>
        <Form.Label>New Password</Form.Label>
        <Form.Control
          name="newPassword1"
          type="password"
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('newPassword1')}
        />

        <Form.Control.Feedback type="invalid">
          {newPassword1Error}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>New Password Again</Form.Label>
        <Form.Control
          name="newPassword2"
          type="password"
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('newPassword2')}
        />

        <Form.Control.Feedback type="invalid">
          Passwords must match.
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="submit">Reset Password</Button>

    </Form>

  </>
}

export default withRouter(ResetPassword)
