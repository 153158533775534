import React from 'react'
import {formatRelative} from 'date-fns'
import comment from '../lib/comments'

import Card from 'react-bootstrap/Card'

import calculateTitle from '../lib/title_calculator'

import './Comment.scss'

const Comment = props => {
  const {comment, user, created_at, name, timestamp} = props.comment

  const commentDate = new Date(parseInt(created_at))
  const commentTimeStamp = commentDate.toLocaleString()
  const relativeDate = formatRelative(commentDate, new Date())

  const isFromMySide = (
    (!props.isGuest && user)
    || (props.isGuest && !user)
  )

  const dynamicStyling = isFromMySide ? 'my-comment shadow-sm' : ''

  const title = calculateTitle({viewerIsGuest: props.isGuest, user, name})

  function playFrom(event) {
    event.preventDefault()
    props.playerRef.current.currentTime = timestamp
    props.playerRef.current.play()
  }

  return (
    <>
      <Card style={{margin: '15px 0 15px 50px'}} className="shadow-sm">
        <Card.Header
          className={dynamicStyling}
          title={commentTimeStamp}>

          <span className="bold">{title}</span> commented {relativeDate}
        </Card.Header>
        <Card.Body className="comment">
          <TimestampLink timestamp={timestamp} playFrom={playFrom} />

          {/* Note: Using a <br/> instead of putting individual lines in a div
          because it allows users to use 2 newline characters to have a space
          between lines if they want. It's the same as what they see when they
          are typing in the textarea.*/}
          {comment.split('\n').map((line, index, lines) => <span key={index}>
            {line}
            {index < (lines.length - 1) && <br/>}
          </span>)}
        </Card.Body>
      </Card>
    </>
  )
}


function TimestampLink({playFrom, timestamp}) {
  if (typeof timestamp !== 'number') return null

  return <>
    <a href="#" className="timestamp-link" onClick={playFrom}>
      <span className="playGlyph">{String.fromCharCode('9658')}</span>
      {comment.formatTimestamp(timestamp)}
    </a>
    &nbsp;-&nbsp;
  </>
}


export default Comment
