import React, { useState, useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import EditProjectModal from './EditProject'
import UserContext from './UserContext'
import projects from '../lib/projects'
import api from "../lib/api";


const ProjectHeader = ({ projectDetails, isGuest }) => {

  useEffect(() => {
    fetchData()
  }, [])

  const [show, setShow] = useState(false)
  const [name, setName] = useState(projectDetails.name)
  const [projectList, setProjectList] = useState([])

  const fetchData = async () => {
    const body = {
      query: `query {
      projectListByOwner {
        id
        name
        created_at
        updated_at
        works {
          id
          name
        }
      }
    }`
    }

    const responseData = await api.makeGraphQlRequest(body)

    if (responseData.data && responseData.data.projectListByOwner && responseData.data.projectListByOwner.length > 0) {
      setProjectList(responseData.data.projectListByOwner)
    }
  }

  const user = React.useContext(UserContext)
  const shareLink = `${document.location.origin}/${projectDetails.access_token}`

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  function select(e) {
    e.target.select()
  }

  const handleInputChange = (e) => {
    setName(e.currentTarget.value)
  }

  function copy(e) {
    navigator.clipboard.writeText(shareLink)
  }

  async function renameProject() {
    const body = {
      query: `mutation($id: Int!, $name: String) {
          renameProject(id: $id, name: $name)
        }`,
      variables: { id: projectDetails.id, name }
    }
    await api.makeGraphQlRequest(body)
    window.location.reload()
  }

  async function deleteProject() {
    if (user.stripe_id) {
      await projects.delete(projectDetails.id)
      window.location.replace('/')
    } else if (projectList.length >= 2) {
      window.location.replace('/purchase')
    } else {
      await projects.delete(projectDetails.id)
      window.location.replace('/')
    }

    // if (user.stripe_id) {
    //   await projects.delete(projectDetails.id)
    //   window.location.replace('/')
    // } else {
    //   window.location.replace('/purchase')
    // }
  }

  return (
    <>

      <Row>
        <Col md={3}>
          <div className="title_wrapper">
            <h1 className="title_head">
              {show ?
                <div>
                  <Form.Control name="name" type={"text"} value={name} onChange={handleInputChange} />
                  <div className="d-flex align-items-center"
                    style={{ marginTop: 7 }}>
                    <Button
                      variant="primary"
                      onClick={renameProject}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="link">
                      Cancel
                    </Button>
                  </div>
                </div> :
                projectDetails.name}
              {/* {
                !isGuest && user.status === 'active' &&
                <EditProjectModal projectDetails={projectDetails} show={show} handleShow={handleShow} handleClose={handleClose} />
              } */}
            </h1>
            <FontAwesomeIcon className="edit_icon"
              icon={faEdit}
              style={{ fontSize: '20px', marginLeft: '.6em', marginRight: 5, cursor: 'pointer' }}
              onClick={handleShow} />
            <FontAwesomeIcon className="edit_icon" icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteProject} />
          </div>
        </Col>
        <Col md={9} className="link_part">
          {
            !isGuest && <Form.Group as={Row}>
              <Col lg={3} sm={4}>
                <Form.Label column xs="auto">Share Link:</Form.Label>
              </Col>
              <Col lg={9} sm={8}>
                <InputGroup>
                  <FormControl value={shareLink} onClick={select} readOnly />
                  {
                    navigator.clipboard && navigator.clipboard.writeText &&
                    <InputGroup.Append>
                      <Button variant="outline-primary" onClick={copy}>
                        Copy
                      </Button>
                    </InputGroup.Append>
                  }
                </InputGroup>
              </Col>
            </Form.Group>
          }
        </Col>
      </Row>

      <hr />
    </>
  )
}

export default ProjectHeader
