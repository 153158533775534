import React from 'react'
import {useState} from 'react'
import {withRouter} from 'react-router-dom'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import userLib from '../lib/user'
import validators from '../lib/validators'
import {useInputs} from '../hooks/useInputs'
import UserContext from './UserContext'
import authClient from '../Auth'



const Settings = ({setUser}) => {
  const user = React.useContext(UserContext)
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [nameSuccess, setNameSuccess] = useState(false)
  const [validated, setValidated] = useState(false)
  const [isInvalid, setIsInvalid] = useState(new Set())
  const {inputValues, handleInputChange} = useInputs({
    name: user.name
  })

  // The new password field can have many potential issues. This stores it.
  const [newPassword1Error, setNewPassword1Error] = useState()

  async function handlePasswordSubmit(event) {
    event.preventDefault()
    // Reset success
    setPasswordSuccess(false)

    if (!isPasswordValid()) return

    const {currentPassword, newPassword1} = inputValues

    // Submit to the API if no validation errors
    try {
      const result = await userLib.changePassword(currentPassword, newPassword1)
      // Show success banner if the API returned a confirmation
      if (result === true) setPasswordSuccess(true)
    }
    catch (err) {
      if (err.message === 'Unauthorized')
        setIsInvalid(new Set(['currentPassword']))
      else
        window.alert(err.message)
    }
  }


  // Handles validating the form before submit
  const isPasswordValid = () => {
    const {currentPassword, newPassword1, newPassword2} = inputValues

    const problems = new Set()
    if (!currentPassword) problems.add('currentPassword')

    const message = validators.password(newPassword1)
    if (message) {
      problems.add('newPassword1')
      setNewPassword1Error(message)
    }
    if (newPassword2 !== newPassword1) problems.add('newPassword2')

    setIsInvalid(problems)
    setValidated(true)

    return problems.size === 0
  }


  async function handleNameSubmit(event) {
    event.preventDefault()
    // Reset success
    setNameSuccess(false)

    const {name} = inputValues

    // This handles an invalid (empty) name
    if (!name) {
      setIsInvalid(new Set(['name']))
      setValidated(true)
      return false
    }

    // Past this point is success
    setIsInvalid(new Set())
    setValidated(true)

    // Submit to the API
    try {
      const result = await userLib.changeName(name)

      // Show success banner if the API returned a confirmation
      if (result === true) {
        setNameSuccess(true)

        // Refresh the name in the page state
        setUser(await authClient.silentAuth())
      }
    }
    catch (err) {
      if (err.message === 'Unauthorized')
        setIsInvalid(new Set(['name']))
      else
        window.alert(err.message)
    }
  }


  return <>
    {passwordSuccess && <Alert variant={'primary'}>
      Password successfully changed
    </Alert>}

    <Form onSubmit={handlePasswordSubmit}>

      <Form.Group>
        <h2>Password</h2>
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          name="currentPassword"
          type="password"
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('currentPassword')}
        />

        <Form.Control.Feedback type="invalid">
          A password is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>New Password</Form.Label>
        <Form.Control
          name="newPassword1"
          type="password"
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('newPassword1')}
        />

        <Form.Control.Feedback type="invalid">
          {newPassword1Error}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>New Password Again</Form.Label>
        <Form.Control
          name="newPassword2"
          type="password"
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('newPassword2')}
        />

        <Form.Control.Feedback type="invalid">
          Passwords must match.
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="submit">Change Password</Button>

    </Form>

    <hr style={{margin:'50px'}} />

    {nameSuccess && <Alert variant={'primary'}>
      Name successfully changed
    </Alert>}

    <Form  onSubmit={handleNameSubmit}>

      <Form.Group>
        <h2>Name</h2>
        <Form.Control
          name="name"
          type="text"
          value={inputValues.name}
          onChange={handleInputChange}
          isInvalid={validated && isInvalid.has('name')}
        />

        <Form.Control.Feedback type="invalid">
          A name is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="submit">Change Name</Button>

    </Form>
  </>
}

export default withRouter(Settings)
