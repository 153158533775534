import React from 'react'
import { formatRelative } from 'date-fns'
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import projects from '../lib/projects'
import UserContext from './UserContext'

import './ProjectCard.scss'


const ProjectCard = props => {
  const user = React.useContext(UserContext)
  const project = props.project
  const name = project && project.name
  const numberOfWorks = project && project.works && project.works.length || 0
  const updatedAt = (
    project
    && project.updated_at
    && formatRelative(new Date(parseInt(project.updated_at)), new Date())
  )

  const pluralS = numberOfWorks === 1 ? '' : 's'

  async function deleteProject() {
    if (user.stripe_id) {
      await projects.delete(project.id)
      window.location.replace('/')
    } else if (props.projectList.length >= 2) {
      window.location.replace('/purchase')
    } else {
      await projects.delete(project.id)
      window.location.replace('/')
    }
    // if (user.stripe_id) {
    //   await projects.delete(project.id)
    //   window.location.replace('/')
    // } else {
    //   window.location.replace('/purchase')
    // }
  }

  return (
    <Link to={`/project/${project.id}`} className="projectCard">
      <Card>
        <Card.Body>
          <Card.Title style={{ display: "flex", justifyContent: "space-between" }}>{name}
            <FontAwesomeIcon className="edit_icon" icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer', color: "#929292" }} onClick={deleteProject} />
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {numberOfWorks} episode{pluralS}
          </Card.Subtitle>
          <Card.Text>
            Updated {updatedAt}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default ProjectCard
