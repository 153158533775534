import React from 'react'

// Bootstrap Components
import Card from 'react-bootstrap/Card'

import './Step.scss'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faEnvelopeOpen} from '@fortawesome/free-regular-svg-icons'

const Verify = ({email}) => {
  return (
    <Card className="step">
      <Card.Body>
        <p>Please check your email to continue the signup process.</p>
      </Card.Body>
    </Card>
  )
}

export default Verify
