import React from "react";
import { withRouter } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js'

import Button from 'react-bootstrap/Button'

import api from '../../lib/api'
import { features, cost } from '../../lib/products'
import { useInputs } from '../../hooks/useInputs'

import "./Step.scss";
import Cliant1 from "../assets/cliant1.png"

const stripePromise = loadStripe(SERVER_DATA.stripe_publishable_key)

function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const { inputValues, handleInputChange } = useInputs()

  const { coupon } = inputValues

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    })
    if (payload.error) {
      window.alert(payload.error.message)

      return
    }

    const response = await purcahase(payload.paymentMethod.id, coupon)
    if (response.errors) {
      for (const error of response.errors)
        window.alert(error.message)

      return
    }

    // Send the user to their billing management page in Stripe
    // as confirmation
    window.location.pathname = '/api/stripe/portal'
  }

  const cardElementOptions = {
    classes: {
      base: 'form-control StripeElement',
    },
  }

  return (
    <form onSubmit={handleSubmit} className="purchaseForm">
      <Row>
        <Col sm={12}>
          <CardElement options={cardElementOptions} />
        </Col>
        <Col sm={12} className="text-center">
          <Button type="submit" disabled={!stripe} className="subscribe_btn">Subscribe</Button>
        </Col>
      </Row>
    </form>
  )
}


async function purcahase(paymentMethodId, coupon) {
  console.log({ coupon })
  const body = {
    query: `mutation($paymentMethodId: String!, $coupon: String) {
      purchase(paymentMethodId: $paymentMethodId, coupon: $coupon)
    }`,
    variables: {
      paymentMethodId,
      coupon,
    }
  }

  return api.makeGraphQlRequest(body)
}

const premiumaccess = () => {

  return (
    <div className="premiumaccess_wrap">
      <Row>
        <Col sm={6}>
          <div className="premiumaccess_heading">
            <h3>Access Premium features and up to 100GB</h3>
            <p>For only {cost}</p>
          </div>
          <div className="premiumaccess_info">
            <h5>Everything in your free account PLUS:</h5>
            <hr />
            <ul>
              {features.map((feature, index) => <li key={index}>
                <FontAwesomeIcon icon={faCheckCircle} />
                <span>{feature}</span></li>
              )}
            </ul>
          </div>
        </Col>
        <Col sm={6}>
          <div className="payment_method">
            <h6 className="payment_method_title">$24 for 12 months premium access</h6>
            <Row>
              <Col sm={12}>
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              </Col>
              <Col sm={12}>
                <p className="payment_method_info">All payments in Australian Dollars <br />Cancel any time</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <div className="cliant_quotes_card">
        <div className="cliant_quotes">
          <div className="cliant_image">
            <img src={Cliant1} alt="Cliant1" />
          </div>
          <div className="cliant_info">
            <p>Jake, Podcast producer</p>
            <h6> “At that price, it was no brainer. I get the feedback I need to get my episodes right”</h6>
          </div>
        </div>
        <div className="cliant_quotes">
          <div className="cliant_image">
            <img src={Cliant1} alt="Cliant1" />
          </div>
          <div className="cliant_info">
            <p>Jake, Podcast producer</p>
            <h6> “At that price, it was no brainer. I get the feedback I need to get my episodes right”</h6>
          </div>
        </div>
        <div className="cliant_quotes">
          <div className="cliant_image">
            <img src={Cliant1} alt="Cliant1" />
          </div>
          <div className="cliant_info">
            <p>Jake, Podcast producer</p>
            <h6> “At that price, it was no brainer. I get the feedback I need to get my episodes right”</h6>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default withRouter(premiumaccess);
