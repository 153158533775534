/* eslint-disable max-len */
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'

// Bootstrap Components
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Alert from 'react-bootstrap/Alert'

import WorkFeed from './WorkFeed'
import ModalWorkUploader from './ModalWorkUploader'
import ProjectHeader from './ProjectHeader'
import ProjectRefreshContext from './ProjectRefreshContext'

import projectFieldsFragment from '../graphql/ProjectFieldsFragment.graphql'

import api from '../lib/api'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import './Project.scss'


const Project = props => {
  const {projectId} = props.match.params
  const {projectToken} = props.match.params
  const {workId} = props.match.params
  const [projectDetails, setProjectDetails] = useState()
  const [errors, setErrors] = useState([])

  const isGuest = !!projectToken

  // Fetch data when the component loads
  useEffect(() => {
    updateProjectData()
  }, [])

  // Update the project data, such as after a save.
  // If projectData is passed in, it will be used. If not,
  // it will be fetched from the API
  async function updateProjectData(projectData) {
    if (projectData) {
      setProjectDetails(responseData.data.projectDetails)
      return
    }

    const body = {
      query: `query($id: ID) {
          projectDetails(id: $id) {
            ...ProjectFields
          }
        }
        ${projectFieldsFragment}`,
      variables: {id: projectId}
    }

    const responseData = await api.makeGraphQlRequest(body, projectToken)

    if (responseData.errors)
      setErrors(responseData.errors.map(e => e.message))

    setProjectDetails(responseData.data.projectDetails)
  }


  function updateProjectWithNewWorks(newWorks) {
    projectDetails.works.push(...newWorks)
    setProjectDetails({
      ...projectDetails,
      works: projectDetails.works.sort(x => x.id)
    })
  }

  if (errors && errors.length) {
    return <ErrorDisplay errors={errors}/>
  }

  if (!projectDetails) return <ErrorDisplay errors={['Link is incorrect or the project has been deleted']}/>

  const objectCompare = (a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }

    return 0
  }
  const sortedWorks = projectDetails.works.sort(objectCompare)
  const defaultWorkId = workId || (sortedWorks[0] && projectDetails.works[0].id)

  // Update the URL to facilitate linking directly to a track
  function workSelected(eventKey, event) {
    if (projectId)
      props.history.push(`/project/${projectId}/track/${eventKey}`)
    else if (projectToken)
      props.history.push(`/${projectToken}/${eventKey}`)
  }

  return (
    <ProjectRefreshContext.Provider value={updateProjectData}>

      <ProjectHeader
        projectId={parseInt(projectId)}
        projectDetails={projectDetails}
        isGuest={isGuest}
        history={props.history} />

      {!sortedWorks.length && <ModalWorkUploader
        projectId={projectId}
        updateProjectWithNewWorks={updateProjectWithNewWorks}
      />}

      <ErrorDisplay errors={errors}/>

      {!!sortedWorks.length && <Tab.Container defaultActiveKey={defaultWorkId}>
        <Row className="project_wrapper_user">
          <Col sm={3}>
            <Nav variant="pills" className="flex-column songs">
              {sortedWorks.map(work => {
                return (
                  <Nav.Item key={work.id}>
                    <Nav.Link eventKey={work.id} onSelect={workSelected}>{getWorkNavText(work)}</Nav.Link>
                  </Nav.Item>
                )})}
            </Nav>
            {
              !isGuest && <>
                <ModalWorkUploader
                  projectId={projectId}
                  updateProjectWithNewWorks={updateProjectWithNewWorks}
                />
              </>
            }
          </Col>

          <Col sm={9}>
            <Tab.Content>
              {projectDetails.works.map(work => (
                <WorkFeed
                  isGuest={isGuest}
                  key={work.id}
                  work={work}
                  projectId={projectDetails.id}
                  projectToken={projectToken}
                  {...props}
                />
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>}
    </ProjectRefreshContext.Provider>
  )
}


function getWorkNavText(work) {
  const approved = work.versions.find(version => version.approval)

  if (approved) {
    return <>
      <FontAwesomeIcon icon={faCheck} />
      &nbsp;{work.name.length > 15 ? `${work.name.slice(0, 15)}...` : work.name}
    </>
  }

  return work.name.length > 15 ? `${work.name.slice(0, 15)}...` : work.name
}


function ErrorDisplay(props) {
  return props.errors.map((error, index) => <Alert key={index} variant="warning">{error}</Alert>)
}


export default withRouter(Project)
