import isEmail from 'validator/lib/isEmail'

const whitespace_but_not_spaces = /[\t\r\n]/


const validators = {
  email_address(email_address) {
    if (!email_address) return 'An email address is required.'
    if (!isEmail(email_address)) return 'Please enter a valid email address.'
  },
  password(password) {
    if (!password)
      return 'A password is required.'
    if (password.length < 8)
      return 'Your password must be at least 8 characters long.'
    if (password.length > 128)
      return 'Your password must be 128 characters or less.'
    if (whitespace_but_not_spaces.test(password))
      return 'Your password may not include non-space whitespace.'
  },
}

export default validators
