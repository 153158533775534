module.exports = `
  fragment ProjectFields on Project {
    id
    name
    created_at
    updated_at
    access_token
    works {
      id
      name
      versions {
        id
        name
        media_location
        created_at
        approval {
          name
          user {
            name
          }
          approved_at
        }
        comments {
          id
          comment
          name
          created_at
          user {
            email
            name
          }
          timestamp
        }
      }
    }
  }
`
