import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";

import projects from '../lib/projects'
import api from "../lib/api";


const EditProjectModal = ({ history, projectDetails, show, handleClose }) => {

  const [name, setName] = useState(projectDetails.name)

  async function deleteProject() {
    await projects.delete(projectDetails.id)

    // Redirect back to project dashboard
    history.replace('/')
  }

  async function renameProject() {
    const body = {
      query: `mutation($id: Int!, $name: String) {
          renameProject(id: $id, name: $name)
        }`,
      variables: { id: projectDetails.id, name }
    }
    await api.makeGraphQlRequest(body)
    window.location.reload()
  }

  const handleInputChange = (e) => {
    setName(e.currentTarget.value)
  }

  return (
    <>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>

        {/* <Modal.Body>
          <Form.Control name="name" type={"text"} value={name} onChange={handleInputChange} />
          <Button
            variant="primary"
            onClick={renameProject}
            style={{ marginTop: 7 }}
          >
            Save
          </Button>
        </Modal.Body> */}

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={deleteProject}
            style={{ backgroundColor: '#e85a4f', borderColor: '#e85a4f' }}
          >
            Delete this project
          </Button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default withRouter(EditProjectModal)
