import api from './api'


const renameWorkVersion = async (id, name) => {
  const body = {
    query: `
      mutation($id: Int!, $name: String) {
        renameWorkVersion(id: $id, name: $name) {
          name
        }
      }
    `,
    variables: {id, name}
  }

  const response = await api.makeGraphQlRequest(body)

  return response.data.renameWorkVersion
}


const renameWork = async (id, name) => {
  const body = {
    query: `
      mutation($id: Int!, $name: String) {
        renameWork(id: $id, name: $name) {
          name
        }
      }
    `,
    variables: {id, name}
  }

  const response = await api.makeGraphQlRequest(body)

  return response.data.renameWork
}


export default {
  renameWork,
  renameWorkVersion,
}
