import {useState} from 'react'

export const useInputs = (initialState) => {
  const [inputValues, setInputValues] = useState(initialState || {})

  const handleInputChange = (e) => setInputValues({
    ...inputValues,
    [e.currentTarget.name]: e.currentTarget.value
  })

  const resetInputValues = () => {
    const newValues = Object.keys(inputValues).reduce(
      (values, currentKey) => {
        values[currentKey] = ''
        return values
      },
      {}
    )

    setInputValues(newValues)
  }

  return {
    inputValues,
    setInputValues,
    resetInputValues,
    handleInputChange,
  }
}
