import cc from 'classcat'
import React, { useState, useEffect } from 'react'
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'

// Bootstrap components
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'

import LandingPage from './LandingPage'
import Loading from './Loading'
import authClient from '../Auth'
import Login from './Login'
import Settings from './Settings'
import Project from './Project'
import Projects from './Projects'
import SecuredRoute from './SecuredRoute'
import AnonymousRoute from './AnonymousRoute'
import EmailStep from './signup_steps/Email'
import VerifyStep from './signup_steps/Verify'
import PasswordStep from './signup_steps/Password'
import ForgotEmail from './signup_steps/ForgotEmail'
import ResetPassword from './ResetPassword'
import DetailsStep from './signup_steps/Details'
import AudioPlayerContext from './AudioPlayerContext'
import UserContext from './UserContext'
import Purchase from './Purchase'
import Terms from './Terms'
import Privacy from './Privacy'

import Logo from './assets/logo.svg'
import CreatePassword from './signup_steps/CreatePassword'
import PremiumAccess from './signup_steps/PremiumAccess'

const App = props => {
  const needToFetchServerData = Object.keys(SERVER_DATA).length === 0
  const [scrolled, setScrolled] = React.useState()
  const [playingTrack, setPlayingTrack] = React.useState()
  const [checkingSession, setCheckingSession] = useState(needToFetchServerData)
  const [user, setUser] = useState(SERVER_DATA.user)

  const handleAuth = async () => {
    try {
      setUser(await authClient.silentAuth())
    }
    catch (err) {
      if (err.error !== 'login_required') {
        throw err
      }
    }

    setCheckingSession(false)
  }

  useEffect(() => {
    // Fetch user data if this is the webpack server and no backend data
    // has been supplied with the page
    if (needToFetchServerData) handleAuth()
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', (() => {
      let frame

      return () => {
        if (frame) {
          cancelAnimationFrame(frame)
        }

        frame = requestAnimationFrame(() => {
          setScrolled(window.pageYOffset > 0)
        })
      }
    })(), { passive: true })
  }, [])

  const handleLogout = async () => {
    await authClient.logout()
    setUser(null)
  }

  const navDropdownTitle = (
    <FontAwesomeIcon
      style={{ fontSize: '1.5em' }}
      icon={faCog}
    />
  )

  // Wonky logic to get a display name for the user based on whether they have
  // a first/last name set up or not.
  let displayName = ''
  if (user) {
    if (user.name) {
      displayName = user.name
    }
    else {
      displayName = user.email
    }
  }

  const getAuthDependentNavElements = () => {
    const location = useLocation();

    if (user) {
      const portalLink = `/api/stripe/portal?return_url=${document.location.href}`

      return (
        <>
          <LinkContainer to="/projects"><Nav.Link>My Projects</Nav.Link></LinkContainer>
          <NavDropdown
            alignRight
            className="settingsDropDown"
            title={navDropdownTitle}
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Header>
              {displayName}
            </NavDropdown.Header>

            <NavDropdown.Divider />

            <LinkContainer to="/settings">
              <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
            </LinkContainer>

            {/* {user.status === 'active' && <a href={portalLink} className="dropdown-item">Manage Subscription</a>} */}

            {/* {user.status !== 'active' && <LinkContainer to="/purchase"> */}
            {<LinkContainer to="/purchase">
              <NavDropdown.Item active={false}>
                Subscribe
              </NavDropdown.Item>
            </LinkContainer>}

            <a href="mailto:support@episodecheck.com" className="dropdown-item">Email Support</a>

            <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </>
      )
    }

    if (location.pathname === "/") {
      return (
        <>
          <LinkContainer to="/login"><Nav.Link className="log_in"><div>Log In</div></Nav.Link></LinkContainer>
          <LinkContainer to="/signup/email">
            <Nav.Link className="signupButton"><div>Free Trial</div></Nav.Link>
          </LinkContainer>
        </>
      )
    }
  }


  // Determines if landing page should be shown based on the path and
  // the presence of a logged-in user
  function showLandingPage(path, user) {
    if (path === '/') {
      if (!user) return true
      else return false
    }
    else {
      return false
    }
  }


  // App-wide function that will pause the currently playing track
  // when you start another
  function pauseOtherTracks({ target }) {
    if (playingTrack && playingTrack !== target) {
      playingTrack.pause()
    }

    setPlayingTrack(target)
  }

  let nav =

    <Navbar className={cc(["App", "shadow-sm", { scrolled }])} variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
        </LinkContainer>
        <Nav className="mr-auto"></Nav>
        <Nav>
          {getAuthDependentNavElements()}
        </Nav>
      </Container>
    </Navbar>


  return (
    <UserContext.Provider value={user}>
      {
        showLandingPage(props.location.pathname, user) ? <>
          {nav}
          <LandingPage />
        </> : <>
          <AudioPlayerContext.Provider value={pauseOtherTracks}>
            {nav}

            <Container fluid={true} style={{ maxWidth: '1200px' }}>
              {checkingSession && <Loading />}
              {/* https://reacttraining.com/react-router/web/example/route-config */}
              {!checkingSession &&
                <Switch>
                  <AnonymousRoute exact path='/' component={LandingPage} />
                  <Route exact path='/terms' component={Terms} />
                  <Route exact path='/privacy' component={Privacy} />
                  <AnonymousRoute exact path='/login' component={Login} setUser={setUser} />
                  <AnonymousRoute exact path='/signup/email' component={EmailStep} />
                  <AnonymousRoute exact path='/signup/verify' component={VerifyStep} />
                  <AnonymousRoute exact path='/signup/createpassword' component={CreatePassword} setUser={setUser} />
                  <AnonymousRoute exact path='/signup/password' component={PasswordStep} setUser={setUser} />
                  <AnonymousRoute exact path='/forgotPassword' component={ForgotEmail} />
                  <AnonymousRoute exact path='/resetPassword' component={ResetPassword} />
                  <SecuredRoute exact path='/signup/details' component={DetailsStep} setUser={setUser} />
                  <SecuredRoute exact path='/settings' component={Settings} setUser={setUser} />
                  {/* <SecuredRoute exact path='/purchase' component={Purchase} /> */}
                  <SecuredRoute path='/purchase' component={PremiumAccess} />
                  <SecuredRoute exact path='/projects' component={Projects} />
                  <SecuredRoute path='/project/:projectId/track/:workId' component={Project} />
                  <Route path='/project/:projectId/' component={Project} />
                  <Route path='/:projectToken/:workId?' component={Project} />
                </Switch>
              }
            </Container>
          </AudioPlayerContext.Provider>
        </>
      }
    </UserContext.Provider>
  )
}

export default withRouter(App)
