import React, {useState, useReducer} from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'

import FileUpload from './FileUpload'
import GenericErrorModal from './GenericErrorModal'

import works from '../lib/works'


const WorkUploadModal = ({projectId, updateProjectWithNewWorks}) => {
  const [show, setShow] = useState(false)
  const [files, dispatch] = useReducer(works.fileReducer, [])
  const [isUploading, setIsUploading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorText, setErrorText] = useState()

  const handleClose = () => {
    dispatch({
      type: 'clearFiles',
    })
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const uploadNewWorks = async () => {
    setIsUploading(true)

    // Handle uploading works to Spaces and the DB
    const promises = []
    for (const file of files) {
      promises.push(works.createNew(file, parseInt(projectId), dispatch))
    }

    try {
      const results = await Promise.all(promises)
      updateProjectWithNewWorks(results)
    }
    catch(err) {
      // Show error modal
      setErrorText(<p>{err.message}</p>)
      setShowErrorModal(true)
      // TODO: send to rollbar
    }

    setIsUploading(false)

    handleClose()
  }

  return (
    <>
      <Button
        variant="outline-primary"
        style={{margin: '20px auto', display: 'block'}}
        onClick={handleShow} >
        <FontAwesomeIcon
          icon={faPlus} />
        &nbsp; Add Episode
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Upload Episode</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FileUpload
            files={files}
            dispatch={dispatch}
            isUploading={isUploading} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isUploading}
            variant="primary"
            onClick={uploadNewWorks}>Upload</Button>
        </Modal.Footer>
      </Modal>

      <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal}>
        {errorText}
      </GenericErrorModal>
    </>
  )
}

export default WorkUploadModal
