import api from './api'


async function changePassword(oldPass, newPass) {
  const body = {
    query: `mutation($oldPass: String!, $newPass: String!) {
      changePassword(oldPass: $oldPass, newPass: $newPass)
    }`,
    variables: {
      oldPass,
      newPass,
    }
  }

  const response = await api.makeGraphQlRequest(body)

  if (response.errors) {
    if (response.errors.length === 1)
      throw new Error(response.errors[0].message)
    else
      throw new Error('Error changing password')
  }

  return response.data.changePassword
}


async function changeName(name) {
  const body = {
    query: `mutation($name: String!) {
      setMyName(name: $name)
    }`,
    variables: {name}
  }

  const response = await api.makeGraphQlRequest(body)

  if (response.errors) {
    if (response.errors.length === 1)
      throw new Error(response.errors[0].message)
    else
      throw new Error('Error changing name')
  }

  return response.data.setMyName
}


export default {
  changePassword,
  changeName,
}
