import React, {useState, useEffect} from 'react'

import Dropzone from 'react-dropzone'
import Row from 'react-bootstrap/Row'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons'

// Project Components
import FileUploadCard from './FileUploadCard'

import './FileUpload.scss'

const FileUpload = ({dispatch, files, multiple, isUploading, description}) => {
  const [fileUploadCards, setFileUploadCards] = useState([])

  const generateCards = filePayload => {
    let key = 0
    const newCards = []
    filePayload.forEach(f => {
      newCards.push(
        <FileUploadCard
          file={f.file}
          percentUploaded={f.percentUploaded}
          key={key} />
      )
      key += 1
    })

    return newCards
  }

  const handleFileDrop = async files => {
    const filePayload = files.map(file => {
      return {
        file,
        percentUploaded: -1,
        uploadStarted: false,
      }
    })

    dispatch({type: 'load', filePayload})
  }

  useEffect(() => {
    const cards = generateCards(files)
    setFileUploadCards(cards)
  }, [files])

  const hideDropzone = isUploading

  const allowMultiple = multiple === false ? false : true

  return (
    <>
      {!hideDropzone && <Dropzone onDrop={handleFileDrop} multiple={allowMultiple}>
        {
          ({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="fileUploadBox">
                  <p>
                    {description || 'Drag and drop files here, or click to upload'}
                  </p>
                  <FontAwesomeIcon
                    className="uploadIcon"
                    icon={faCloudUploadAlt} />
                </div>
              </div>
            </section>
          )
        }
      </Dropzone>}

      <Row>
        {fileUploadCards}
      </Row>
    </>
  )
}

export default FileUpload
