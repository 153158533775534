import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import UserContext from './UserContext'
import GenericErrorModal from './GenericErrorModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'


const InlineDelete = ({ props, handleDelete }) => {
  const [showModal, setShowModal] = useState(false)
  const user = React.useContext(UserContext)

  const confirm = <ConfirmModal setShowModal={setShowModal} callback={handleDelete} />
  const paywall = <PaywallModal setShowModal={setShowModal} />
  const appropriateModal = user.status === 'active' ? confirm : paywall

  const handleShow = () => {
    setShowModal(true)
    // if (user.stripe_id) {
    //   setShowModal(true)
    // } else {
    //   props.history.replace('/purchase')
    // }
  }

  return <>
    <FontAwesomeIcon
      icon={faTrash}
      style={{ fontSize: '14px', marginLeft: '.6em', cursor: 'pointer', color: '#929292' }}
      onClick={handleShow}
    />
    {showModal && appropriateModal}
  </>
}


function ConfirmModal({ callback, setShowModal }) {
  function yes() {
    callback()
    setShowModal(false)
  }

  function no() {
    setShowModal(false)
  }

  return (
    <Modal show={true} onHide={no} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={yes}>
          Yes
        </Button>

        <Button
          variant="primary"
          onClick={no}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


function PaywallModal({ setShowModal }) {
  return <GenericErrorModal show={true} setShow={setShowModal} title={'Paid Feature'}>
    <p><a href="/purchase">Subscribe</a> to enable deletion.</p>
  </GenericErrorModal>
}


export default InlineDelete
