export const features = [
  'No login necessary for clients',
  'Full resolution audio',
  'Comment notifications via email',
  'Unlimited projects, episodes, revisions and collaborators',
  '100 GB storage',
]


export const cost = '$24 AUD per year'


export const trialFeatures = [
  '2 weeks',
  '250 MB of storage',
  'no credit card required',
]
