import React, { useReducer, useState, useContext } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import works from '../lib/works'
import comments from '../lib/comments'
import rename from '../lib/rename'
import deleteLib from '../lib/delete'
import { useInputs } from '../hooks/useInputs'

import Comment from './Comment'
import FileUpload from './FileUpload'
import WorkVersion from './WorkVersion'
import InlineRename from './InlineRename'
import ProjectRefreshContext from './ProjectRefreshContext'
import GenericErrorModal from './GenericErrorModal'


const WorkFeed = props => {
  const { work } = props
  const refreshProject = useContext(ProjectRefreshContext)
  const { inputValues, handleInputChange, resetInputValues, setInputValues } = useInputs()
  const [files, dispatch] = useReducer(works.fileReducer, [])
  const [isUploading, setIsUploading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorText, setErrorText] = useState()
  const uploadDescription = 'Upload new version of this episode'

  // ref so comments can access the audio player.
  // it's intentional that the last ref set after looping over all the work
  // versions will be used for timestamping the comment
  let playerRefs = work.versions && work.versions.map(() => React.createRef())

  const renameWork = async name => {
    await rename.renameWork(work.id, name)
    await refreshProject()
  }

  const handleComment = async (event) => {
    // Don't do a form submit
    event.preventDefault()

    // No comment, do nothing
    if (!inputValues.comment) return

    // Reset the inputs
    resetInputValues()
    // Save the name in localStorage to prepopulate next time
    localStorage.setItem('name', inputValues.name)

    await comments.create({
      workVersionId: work.versions[work.versions.length - 1].id,
      comment: inputValues.comment,
      timestamp: inputValues.timestamp ? playerRefs[playerRefs.length - 1].current.currentTime : null,
      name: inputValues.name,
      projectToken: props.projectToken,
    })

    await refreshProject()
  }

  const uploadNewWorkVersion = async projectId => {
    setIsUploading(true)

    try {
      await works.createVersion(
        files[0],
        props.projectId,
        work.id,
        dispatch
      )

      // need to refresh the page at this point because if the number
      // of work versions on the page changes, react gets confused
      // (there are more calls to useRef())
      window.location = window.location
    }
    catch (err) {
      setErrorText(<p>{err.message}</p>)
      setShowErrorModal(true)

      dispatch({ type: 'clearFiles' })
      setIsUploading(false)
    }
  }

  const deleteWorkVersion = async (id) => {
    await deleteLib.deleteWorkVersion(id)
    // need to refresh the page at this point because if the number
    // of work versions on the page changes, react gets confused
    // (there are more calls to useRef())
    window.location = window.location
  }


  // detect a new file in the queue and begin the upload
  if (files.length && files.length === 1 && !files[0].uploadStarted)
    uploadNewWorkVersion()

  return (
    <>
      <Tab.Pane eventKey={work.id}>
        <h2 className="title_wrap_content">
          {
            props.isGuest
              ? work.name
              :
              <InlineRename handleSave={renameWork}>{work.name}</InlineRename>
          }
        </h2>

        {work.versions && work.versions.map((workVersion, index) => {
          return <React.Fragment key={workVersion.id}>
            <WorkVersion
              props={props}
              index={index}
              workVersion={workVersion}
              isGuest={props.isGuest}
              projectToken={props.projectToken}
              ref={playerRefs[index]}
              deleteWorkVersion={() => deleteWorkVersion(workVersion.id)}
            />

            {workVersion.comments && workVersion.comments.map(comment =>
              <Comment
                key={comment.id}
                comment={comment}
                isGuest={props.isGuest}
                playerRef={playerRefs[index]}
              />
            )}
          </React.Fragment>
        })}

        <hr />

        {work.versions.length === 0 ? null : <Row>
          <Col>
            <Form onSubmit={handleComment}>
              {props.isGuest &&
                <Form.Control
                  style={{ marginBottom: '5px' }}
                  name="name"
                  as="input"
                  rows="3"
                  placeholder="Your name"
                  defaultValue={localStorage.getItem('name')}
                  onChange={handleInputChange}
                />
              }
              <TimestampCheckbox
                playerRef={playerRefs[playerRefs.length - 1]}
                inputValues={inputValues}
                setInputValues={setInputValues}
              />
              <Form.Control
                className="leave_comment"
                name="comment"
                as="textarea"
                rows="3"
                placeholder="Leave a comment"
                onChange={handleInputChange}
                value={inputValues.comment}
              />
              <Button
                variant="outline-primary"
                type="submit"
                style={{ marginTop: '8px' }}
                className="float-right comment_btn_wrap"
                disabled={false}
              >
                Comment
              </Button>
            </Form>
          </Col>
        </Row>}

        {
          !props.isGuest &&
          <Form>
            <Form.Group className="file_upload_box">
              <FileUpload
                files={files}
                multiple={false}
                dispatch={dispatch}
                isUploading={isUploading}
                description={uploadDescription}
              />
            </Form.Group>
          </Form>
        }

      </Tab.Pane>

      <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal}>
        {errorText}
      </GenericErrorModal>
    </>
  )
}

export default WorkFeed



function TimestampCheckbox({ inputValues, setInputValues, playerRef }) {
  const inputRef = React.useRef()

  function onChange() {
    // set the value of this thing as true or false so that it can be handled
    // like a normal form element by the existing framework
    setInputValues({ ...inputValues, timestamp: !!inputRef.current.checked })
  }

  let timestamp = null
  if (inputValues.timestamp && playerRef?.current?.currentTime !== undefined)
    timestamp = comments.formatTimestamp(playerRef.currentTime)

  const uncheckedBoxLabel = <>Include timestamp of current player position</>

  const checkedBoxLabel = <>Comment timestamp: <strong>{timestamp}</strong></>

  return <>
    <label class="container_checkbox">
      <input
        type="checkbox"
        checked={!!inputValues.timestamp}
        value={inputValues.timestamp}
        onChange={onChange}
        style={{ marginRight: '7px' }}
        ref={inputRef}
        name="timestamp"
      />
      <span class="checkmark"></span>
      {inputValues.timestamp ? checkedBoxLabel : uncheckedBoxLabel}
    </label>
  </>
}
