import api from './api'
import projectFieldsFragment from '../graphql/ProjectFieldsFragment.graphql'


// Helper to create a comment based on form inputs.
const create = async ({workVersionId, comment, name, projectToken, timestamp}) => {
  const body = {
    query: `mutation($workVersionId: Int!, $comment: String!, $name: String, $timestamp: Float) {
        createComment(
          workVersionId: $workVersionId,
          comment: $comment,
          name: $name,
          timestamp: $timestamp) {
          ...ProjectFields
        }
      }
      ${projectFieldsFragment}`,
    variables: {workVersionId, comment, name, timestamp}
  }
  const res = await api.makeGraphQlRequest(body, projectToken)

  if (res.errors) throw new Error('Error creating comment')

  const comments = res.data.createComment

  return comments
}


function formatTimestamp(seconds) {
  return (seconds - (seconds%=60))/60 + (9 < seconds ? ':' : ':0') + Math.floor(seconds)
}


export default {
  create,
  formatTimestamp,
}
