/**
 * Handles making GraphQL requests to the backend API.
 */
const makeGraphQlRequest = async (body, projectToken) => {
  const headers = {'Content-Type': 'application/json'}

  // Add project token if this is a collaborator request
  if (projectToken) headers.ProjectToken = projectToken

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  }

  const response = await fetch('/graphql', options)

  const json = await response.json()

  // Log errors to the console for some clues
  if (json.errors) console.dir(json.errors, {depth: null}) //eslint-disable-line no-console

  // But also return the json like normal. It'll be up to calling
  // code to deal with errors appropriately.
  return json
}

/**
 * Handles making non-GraphQL REST style requests to the backend API.
 */
const makeRestRequest = async (path, method, body) => {
  if (!path.startsWith('/')) path = `/${path}`
  path = `/api${path}`

  const headers = {'Content-Type': 'application/json'}
  const options = {
    method,
    body: JSON.stringify(body),
    headers,
  }

  const response = await fetch(path, options)

  return response
}

export default {
  makeGraphQlRequest,
  makeRestRequest,
}
