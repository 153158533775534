import React from 'react'

import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


const FileUploadCard = props => {
  const style = {
    width: props.percentUploaded + '%',
    backgroundColor: '#e85a4f',
    height: '5px'
  }

  return (
    <>
      <Col style={{margin: '5px 0 5px 0'}} sm="12">
        <Card>
          <Card.Body>
            <Card.Text>
              {props.file.name}
            </Card.Text>
            {(props.percentUploaded !== -1) && <div style={{border: '1px solid #ced4da'}}>
              <div style={style}></div>
            </div>}
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default FileUploadCard
