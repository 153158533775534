import React from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {useStripe, useElements, Elements, CardElement} from '@stripe/react-stripe-js'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import api from '../lib/api'
import {features, cost} from '../lib/products'
import {useInputs} from '../hooks/useInputs'

import './Purchase.scss'


const stripePromise = loadStripe(SERVER_DATA.stripe_publishable_key)

export default function Purchase() {
  return <>
    <h1>Subscribe to Episode Check</h1>
    <h2>{cost}</h2>

    <h3>Features</h3>
    <ul>
      {features.map((feature, index) => <li key={index}>{feature}</li>)}
    </ul>

    <hr/>

    <Elements stripe={stripePromise}>
      <CheckoutForm/>
    </Elements>
  </>
}


function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const {inputValues, handleInputChange} = useInputs()

  const {coupon} = inputValues

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    })
    if (payload.error) {
      window.alert(payload.error.message)

      return
    }

    const response = await purcahase(payload.paymentMethod.id, coupon)
    if (response.errors) {
      for (const error of response.errors)
        window.alert(error.message)

      return
    }

    // Send the user to their billing management page in Stripe
    // as confirmation
    window.location.pathname = '/api/stripe/portal'
  }

  const cardElementOptions = {
    classes: {
      base: 'form-control StripeElement',
    },
  }

  return (
    <form onSubmit={handleSubmit} className="purchaseForm">
      <label>Card details</label>
      <CardElement options={cardElementOptions}/>
      <label>Coupon Code</label>
      <Form.Control
              name="coupon"
              type="text"
              onChange={handleInputChange}
      />
      <Button type="submit" disabled={!stripe}>Submit</Button>
    </form>
  )
}


async function purcahase(paymentMethodId, coupon) {
  console.log({coupon})
  const body = {
    query: `mutation($paymentMethodId: String!, $coupon: String) {
      purchase(paymentMethodId: $paymentMethodId, coupon: $coupon)
    }`,
    variables: {
      paymentMethodId,
      coupon,
    }
  }

  return api.makeGraphQlRequest(body)
}
