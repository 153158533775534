import React from 'react'
import {Route, Redirect} from 'react-router-dom'

import UserContext from './UserContext'


const SecuredRoute = ({component: Component, path, setUser}) => {
  const user = React.useContext(UserContext)

  return (
    <Route path={ path } render={() => {
      if (!user) {
        return <Redirect to="/login" />
      }

      return <Component user={user} setUser={setUser} />
    }} />
  )
}

export default SecuredRoute
