import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import App from './components/App'

import './common/custom_bootstrap.scss'


render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector('#main')
)
