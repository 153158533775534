import api from './api'


export async function approve(workVersionId, name, projectToken) {
  const body = {
    query: `mutation($id: Int!, $name: String) {
      approveWorkVersion(id: $id, name: $name)
    }`,
    variables: {id: workVersionId, name}
  }

  await api.makeGraphQlRequest(body, projectToken)
}


export async function unapprove(workVersionId, name, projectToken) {
  const body = {
    query: `mutation($id: Int!, $name: String) {
      unapproveWorkVersion(id: $id, name: $name)
    }`,
    variables: {id: workVersionId, name}
  }

  await api.makeGraphQlRequest(body, projectToken)
}
