import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

// Bootstrap Components
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

import api from '../lib/api'
import ProjectCard from './ProjectCard'
import NewProject from './NewProject'

import './Projects.scss'

const Projects = props => {
  const [projectList, setProjectList] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const buildProjectDisplay = () => {
    if (projectList && projectList.length) {
      const projectCards = projectList.map(project => {
        return (
          <Col style={{ margin: '15px 0 15px 0' }} key={project.id} sm="4">
            <ProjectCard project={project} projectList={projectList} />
          </Col>
        )
      })
      return projectCards
    }
  }

  const fetchData = async () => {
    const body = {
      query: `query {
      projectListByOwner {
        id
        name
        created_at
        updated_at
        works {
          id
          name
        }
      }
    }`
    }

    const responseData = await api.makeGraphQlRequest(body)

    if (responseData.data.projectListByOwner.length > 0) {
      setProjectList(responseData.data.projectListByOwner)
    } else {
      setShowMessage(true)
    }
    if (responseData.errors)
      setErrors(responseData.errors.map(e => e.message))
  }


  if (errors && errors.length) {
    return <ErrorDisplay errors={errors} />
  }
  else {
    return (
      <>
        <NewProject projectList={projectList} />
        <hr />
        <Row>
          {buildProjectDisplay()}
          {showMessage &&
            <div style={{ padding: '10px', maxWidth: '800px', margin: 'auto' }}>
              <div className="welcomeDiv">
                <h2>
                  &#128075; Welcome to <span className="bold">Episode Check</span>
                </h2>
                <h5>
                  To get started, create a new project by clicking the
                  &ldquo;New Project&rdquo; button above.
                </h5>
                <p>
                  <span className="bold">Tip</span>: A project should contain all
                  the episodes you want to give a client access to with a single link.
                </p>
              </div>
            </div>
          }
        </Row>
      </>
    )
  }
}


function ErrorDisplay(props) {
  return props.errors.map((error, index) => <Alert key={index} variant="warning">{error}</Alert>)
}


export default withRouter(Projects)
