export default function calculateTitle({viewerIsGuest, user, name}) {
  const isFromMySide = (!viewerIsGuest && user) || (viewerIsGuest && !user)

  // Default the title to the name on the comment or the user name
  let title = name || (user && user.name)

  if (viewerIsGuest) {
    // Default to names for the artist since there could be multiple
    title = title || (isFromMySide ? 'Artist' : 'Engineer')
  }
  else {
    // Default to "You" for the engineer since we know it's them
    title = isFromMySide ? 'You' : (title || 'Artist')
  }

  return title
}
