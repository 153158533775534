import React, {useState, useContext} from 'react'
import {formatRelative} from 'date-fns'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import InlineRename from './InlineRename'
import InlineDelete from './InlineDelete'
import AudioPlayerContext from './AudioPlayerContext'
import ProjectRefreshContext from './ProjectRefreshContext'
import rename from '../lib/rename'
import calculateTitle from '../lib/title_calculator'
import {approve, unapprove} from '../lib/approval'

import './WorkVersion.scss'

const WorkVersion = React.forwardRef(({props, workVersion, isGuest, index, projectToken, deleteWorkVersion}, playerRef) => {
  const [name, setName] = useState(workVersion.name)
  const pauseOtherTracks = useContext(AudioPlayerContext)

  const workVersionDate = new Date(parseInt(workVersion.created_at))
  const workVersionTimeStamp = workVersionDate.toLocaleString()
  const versionDescription = `Version ${index+1}` + (name ? `: ${name}` : '')

  const renameWorkVersion = async name => {
    const newWorkVersion = await rename.renameWorkVersion(workVersion.id, name)
    setName(newWorkVersion.name)
  }

  function ApprovalSwitch({id, approved, versionName}) {
    const [showModal, setShowModal] = useState(false)
    const refreshProject = useContext(ProjectRefreshContext)

    function handleSwitchChange() {
      if (isGuest) // only show the modal for anon users
        setShowModal(true)
      else
        toggleApproval()
    }

    async function toggleApproval(name) {
      setShowModal(false)

      if (approved)
        await unapprove(id, name, projectToken)
      else
        await approve(id, name, projectToken)

      refreshProject()
    }

    const modal = <ApprovalModal
      name={versionName}
      id={id}
      approved={approved}
      toggleApproval={toggleApproval}
      close={() => setShowModal(false)}
    />

    return <>
      <Form.Check
        id={id}
        type="switch"
        label="Final Version"
        onChange={handleSwitchChange}
        checked={approved} />

      {showModal && modal}
    </>
  }

  const cardClassName = [
    'work-version-card',
    'shadow-sm',
    workVersion.approval ? 'approved' : '',
  ].join(' ')

  return (
    <>
      <Card className={cardClassName}>
        <Card.Header className="card_header_wrap" title={workVersionTimeStamp}>
          <div className="card_header_title">
          {getHeading(workVersion, isGuest)}
          </div>
          <ApprovalSwitch
            id={workVersion.id}
            approved={!!workVersion.approval}
            versionName={versionDescription}/>
        </Card.Header>
        <Card.Body className='work' >
          <Card.Title className="version_title">
            {
              isGuest ?
                versionDescription :
                <>
                  <InlineRename
                    label={`Version ${index+1}${name ? ':' : ''}`}
                    handleSave={renameWorkVersion}>
                    {name}
                  </InlineRename>
                  <InlineDelete handleDelete={deleteWorkVersion} props={props} />
                </>
            }
          </Card.Title>

          <audio controls onPlay={pauseOtherTracks} ref={playerRef}>
            <source src={workVersion.media_location} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </Card.Body>
      </Card>
    </>
  )
})


function getHeading(workVersion, isGuest) {
  const {approval} = workVersion

  if (approval) {
    const params = {
      viewerIsGuest: isGuest,
      user: approval.user,
      name: approval.name,
    }
    const title = calculateTitle(params)

    const approvalDate = new Date(parseInt(approval.approved_at))
    const relativeDate = formatRelative(approvalDate, new Date())
    return `Approved ${relativeDate} by ${title}`
  }
  else {
    const workVersionDate = new Date(parseInt(workVersion.created_at))
    const relativeDate = formatRelative(workVersionDate, new Date())
    return `Uploaded ${relativeDate}`
  }
}


function ApprovalModal({name, approved, close, toggleApproval}) {
  const nameInput = React.createRef()

  function submit(event) {
    event.preventDefault()
    toggleApproval(nameInput.current.value)
    // Save the name in localStorage to prepopulate next time
    localStorage.setItem('name', nameInput.current.value)
  }

  return (
    <Modal show={true} onHide={close} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>

      {<Modal.Body>
        <Form.Label>Your name</Form.Label>
        <Form.Control
          style={{marginBottom: '5px'}}
          name="name"
          ref={nameInput}
          as="input"
          rows="3"
          defaultValue={localStorage.getItem('name')}
          placeholder="Your name" />
      </Modal.Body>}

      <Modal.Footer>
        {!approved && <Button
          variant="primary"
          onClick={submit}>
            Approve This Version
        </Button>}

        {approved && <Button
          variant="primary"
          onClick={submit}>
            Unapprove This Version
        </Button>}
      </Modal.Footer>
    </Modal>
  )
}


export default WorkVersion
