import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useInputs} from '../../hooks/useInputs'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import api from '../../lib/api'
import Auth from '../../Auth'

import './Step.scss'
import GenericErrorModal from '../GenericErrorModal'

const Details = ({history, setUser}) => {
  const {inputValues, handleInputChange} = useInputs()
  const [isInvalid, setIsInvalid] = useState(new Set())
  const [nameError, setNameError] = useState()
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isValid()) return

    const {name} = inputValues
    const body = {
      query: `
        mutation($name: String!) {
          setMyName(name: $name)
        }
      `,
      variables: {
        name,
      }
    }

    try {
      // Save the name
      await api.makeGraphQlRequest(body)
      // Then update the state of the app with it
      setUser(await Auth.silentAuth())
    }
    catch (err) {
      return setShowErrorModal(true)
    }

    return history.replace('/projects')
  }

  // Handles validating the form before submit
  const isValid = () => {
    const {name} = inputValues

    // Check the form for validity
    const problems = new Set()

    if (!name) {
      problems.add('name')
      setNameError('A name is required.')
    }

    if (problems.size) setIsInvalid(problems)

    // If there are no problems return true
    return problems.size === 0
  }

  return (
    <Card className="step">
      <Card.Body>
        <p>Extra Details</p>
        <Form onSubmit={handleSubmit} className="form">
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              onChange={handleInputChange}
              isInvalid={isInvalid.has('name')}
              autoFocus />

            <Form.Control.Feedback type="invalid">
              {nameError}
            </Form.Control.Feedback>

            <Form.Text className="text-muted">
              We&apos;ll use your name to label your comments
            </Form.Text>
          </Form.Group>

          <Button
            type="submit"
            className="nextButton"
            variant="outline-primary">Finish!</Button>
        </Form>
      </Card.Body>

      <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal}/>
    </Card>
  )
}

export default withRouter(Details)
