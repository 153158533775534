import React, {useState} from 'react'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'

// Bootstrap Components
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import api from '../../lib/api'
import authClient from '../../Auth'
import validators from '../../lib/validators'
import {useInputs} from '../../hooks/useInputs'

import './Step.scss'
import GenericErrorModal from '../GenericErrorModal'

const Password = ({history, location, setUser}) => {
  const {inputValues, handleInputChange} = useInputs()
  const params = queryString.parse(location.search)
  const email = params.email

  const [isInvalid, setIsInvalid] = useState(new Set())
  const [validated, setValidated] = useState(false)
  const [password1Error, setPassword1Error] = useState()
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isValid()) return

    const token = params.token
    const newPass = inputValues.password1
    const body = {
      query: `mutation($email: String!, $token: String! $newPass: String!) {
        createPassword(email: $email, token: $token, newPass: $newPass)
      }`,
      variables: {
        email,
        token,
        newPass,
      }
    }

    try {
      await api.makeGraphQlRequest(body)
    }
    catch (err) {
      return setShowErrorModal(true)
    }

    const requestBody = {
      email,
      password: newPass,
    }

    // Then log the user in
    try {
      await api.makeRestRequest('login', 'POST', requestBody)
      setUser(await authClient.silentAuth())
    }
    catch (err) {
      return setShowErrorModal(true)
    }

    return history.replace('/signup/details')
  }

  // Handles validating the form before submit
  const isValid = () => {
    const {password1, password2} = inputValues

    // Check the form for validity
    const problems = new Set()
    // Make sure the password meets the rules
    const message = validators.password(password1)
    if (message) {
      problems.add('password1')
      setPassword1Error(message)
    }

    // Make sure the passwords are equal length
    if (password2 !== password1) {
      problems.add('password2')
    }

    setIsInvalid(problems)
    setValidated(true)

    // If there are no problems return true
    return problems.size === 0
  }


  return (
    <Card className="step">
      <Card.Body>
        <p>Create a password</p>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="disabledEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="text"
              disabled={true}
              value={params.email} />
          </Form.Group>

          <hr />

          <Form.Group controlId="password1">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password1"
              type="password"
              onChange={handleInputChange}
              isInvalid={validated && isInvalid.has('password1')}
              autoFocus />
            <Form.Control.Feedback type="invalid">
              {password1Error}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password2">
            <Form.Label>Verify Password</Form.Label>
            <Form.Control
              name="password2"
              type="password"
              onChange={handleInputChange}
              isInvalid={validated && isInvalid.has('password2')} />
            <Form.Control.Feedback type="invalid">
              Passwords must match.
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            type="submit"
            className="nextButton">
              Next &gt;
          </Button>
        </Form>
      </Card.Body>

      <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal}/>
    </Card>
  )
}

export default withRouter(Password)
