import React, { useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

// Bootstrap Components
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import api from "../../lib/api";
import authClient from "../../Auth";
import validators from "../../lib/validators";
import projects from '../../lib/projects'
import { useInputs } from "../../hooks/useInputs";


import "./Step.scss";
import GenericErrorModal from "../GenericErrorModal";

const CreatePassword = ({ history, location, setUser }) => {
  const { inputValues, handleInputChange } = useInputs();
  const params = queryString.parse(location.search);
  const email = location.state.email;

  const [isVisible, setIsVisible] = useState(false);
  const [isInvalid, setIsInvalid] = useState(new Set())
  const [password1Error, setPassword1Error] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValid()) return;

    const token = "token";
    const newPass = inputValues.password1;
    const body = {
      query: `mutation($email: String!, $token: String! $newPass: String!) {
        createPassword(email: $email, token: $token, newPass: $newPass)
      }`,
      variables: {
        email,
        token,
        newPass,
      },
    };

    try {
      await api.makeGraphQlRequest(body);
    } catch (err) {
      return setShowErrorModal(true);
    }

    const requestBody = {
      email,
      password: newPass,
    };

    // Then log the user in
    try {
      await api.makeRestRequest("login", "POST", requestBody);
      await setUser(await authClient.silentAuth());
      const projectId = await projects.create("Project 1")
      history.push(`/project/${projectId}`)
    } catch (err) {
      return setShowErrorModal(true);
    }
  };


  const isValid = () => {
    const { password1 } = inputValues;
    // Check the form for validity
    const problems = new Set();
    // Make sure the password meets the rules
    const message = validators.password(password1);
    if (message) {
      problems.add("password1");
      setPassword1Error(message);
    }

    if (problems.size) setIsInvalid(problems)

    // If there are no problems return true
    return problems.size === 0;
  };

  return (
    <div className="final_step_wrap">
      {/* <div className="upload_file_name">
        <div class="successfully_icon"></div>
        <p>File “alaska.mp3” successfully loaded</p>
        <button>undo</button>
      </div> */}
      <Card className="step">
        <Card.Body>
          <p>Final Step</p>

          <Form onSubmit={handleSubmit} className="form">
            <Form.Group controlId="password1">
              <Form.Label>Create a Password</Form.Label>
              <div className="fill_password">
                <Form.Control name="password1" type={isVisible ? "text" : "password"} value={params.email}
                  onChange={handleInputChange}
                  isInvalid={isInvalid.has('password1')}
                />
                <FontAwesomeIcon icon={faEye} onClick={() => setIsVisible(!isVisible)} />
                <Form.Control.Feedback type="invalid">
                  {password1Error}
                </Form.Control.Feedback>
              </div>

              <Form.Text className="text-muted form_text">
                At least 8 characters
              </Form.Text>
            </Form.Group>

            <Button type="submit" className="nextButton">
              Submit
            </Button>
          </Form>
        </Card.Body>
        {/* {redirect && <Redirect to="/project/123" />} */}
        <GenericErrorModal show={showErrorModal} setShow={setShowErrorModal} />
      </Card>
    </div>
  );
};

export default withRouter(CreatePassword);
